// eslint-disable-next-line no-use-before-define
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Loadable from '@loadable/component';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const TheMap = Loadable(() => import('../components/TheMap'));

// eslint-disable-next-line
import SEO from '../components/SEO';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: '20px',
    color: 'hsl(0, 0%, 16%) !important',
  },
}));

function Home() {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div>
      <SEO title="Home" />
      <Paper className={classes.root} elevation={2}>
        <Typography variant="h5" component="h1" color="inherit">
          Welcome
        </Typography>
        <Typography variant="body1" color="inherit">
          This is the QuikTools front page. Below should be a map. This is just
          a test of mapping things.
        </Typography>
        <TheMap />
      </Paper>
    </div>
  );
}
export default Home;
